<template>
  <base-dialog
    type="icon"
    :width="230"
    icon="$edit"
    small
    x_small
    title="Editar Caja"
    @open="open"
    ref="dialog"
    basic_btn
    @save="$refs.form.validate()"
  >
    <Form @validate="submit" :cash_register="cash_register" ref="form"></Form>
  </base-dialog>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "EditCashRegister",
  props: {
    cash_register: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {};
  },
  components: {
    Form: () => import("@/components/expense/FormCashRegister"),
  },
  methods: {
    open() {},
    ...mapActions("cash_register", ["updateCashRegister"]),
    submit() {
      this.$refs.dialog.close();
      this.updateCashRegister({
        id: this.cash_register.id,
        cashRegister: this.cash_register,
      }).then(() => {
        this.$refs.dialog.close();
        this.$emit("update");
      });
    },
  },
};
</script>

<style>
</style>